import Logo from "../img/logo.png"
const MenuConfig = {
    "Project":{
        "HomeURL":"/",
        "LogoLink":Logo,
    }
}

export{
    MenuConfig
}