import React from "react";

export default function FooterCopirights (){
    return(
        <>
            <div class="copirights">
                <p><b> Resbala Custo </b> é um site de publicidade analitica das instituiçoes cadastradas , permitindo uma confirmação de promoçoes ou derivados , assim não é uma loja e não atua prestando serviço de venda de produtos ou serviços aos seus usuarios pois o projeto atua redirecionando a empresa responsavel pela venda do produto .  </p>
                <p> Resbala Custos é uma realização  <a href="https://alexandrepicinato.com"> alexandrepicinato.com</a></p><a href="https://alexandrepicinato.com">
            </a></div>
        </>
    )
}